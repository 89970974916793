
import gptModule from '@/store/modules/gptModule';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class TicketSupport extends Vue {
  rowData: any = [];

  dataLoading = false;

  get gasLiftData() {
    return gptModule.gasLiftData;
  }
  getKeyMatches(obj, searchKeys) {
    const keys = Object.keys(obj);

    let result: any = null;
    keys.forEach((key) => {
      if (key) {
        if (key.toLowerCase().includes(searchKeys.toLowerCase())) {
          result = key;
        }
      }
    });

    return result;
  }

  depthKey = 'depth'
  typeKey = 'type'
  psoKey = 'pso'
  ptroKey = 'ptro'
  pscKey = 'psc'

  get gasLiftTableData() {
    this.depthKey = 'depth';
    let typeKey = 'type';
    if (
      this.gasLiftData &&
      this.gasLiftData['data'] &&
      this.gasLiftData['data']['schematics'] &&
      this.gasLiftData['data']['schematics'][0]
    ) {
      const firstObj = this.gasLiftData['data']['schematics'][0][0];
      if (firstObj) {
        console.log(firstObj);
        this.depthKey = this.getKeyMatches(firstObj, this.depthKey);
        typeKey = this.getKeyMatches(firstObj, typeKey);
        console.log(typeKey);
        this.typeKey = typeKey
        this.psoKey = this.getKeyMatches(firstObj, this.psoKey);
        this.ptroKey = this.getKeyMatches(firstObj, this.ptroKey);
        this.pscKey = this.getKeyMatches(firstObj, this.pscKey);

      }
      return this.gasLiftData['data']['schematics'][0]
        .filter((item) => item[typeKey] === 'gas_lift_valve')
        .sort((a, b) => {
          if (a[this.depthKey] > b[this.depthKey]) return 1;
          if (a[this.depthKey] < b[this.depthKey]) return -1;
          return 0;
        });
    }

    return [];
  }


  deleteRow(event){
    gptModule.updateGasLiftData({depthValue: event[this.depthKey], depthKey: this.depthKey,  typeValue: event[this.typeKey], typeKey: this.typeKey })
  }

  async created() {
    this.dataLoading = true;
    this.dataLoading = false;
  }
}
